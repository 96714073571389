import React from "react";

interface InputFieldProps {
  label: string;
  name: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  step?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  value,
  onChange,
  step = "1",
}) => (
  <div className="mb-4">
    <label
      className="input-label block text-sm font-medium mb-1"
      htmlFor={name}
    >
      {label}
    </label>
    <input
      type="number"
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      step={step}
      className="input-field w-full px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-mtc-blue focus:border-transparent"
    />
  </div>
);

export default InputField;
