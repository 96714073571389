import React from "react";
import InteractiveFinancialModel from "./components/InteractiveFinancialModel";
import "./styles/custom-styles.css";

function App() {
  return (
    <div className="App min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-7xl">
        <InteractiveFinancialModel />
      </div>
    </div>
  );
}

export default App;
