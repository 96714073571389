import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  TooltipProps,
} from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import InputField from "./InputField";
import SummaryCard from "./SummaryCard";
import "../styles/custom-styles.css";

interface InputData {
  schoolsParticipating: number;
  studentsPerSchool: number;
  studentPackageCost: number;
  mobileGamersTarget: number;
  mobileGameRechargeAmount: number;
  averageRechargesPerYear: number;
  annualPartnershipInvestment: number;
  schoolInternetCost: number;
  esportsCenterCost: number;
}

interface Projection {
  year: number;
  educationIncome: number;
  mobileGamingIncome: number;
  totalIncome: number;
  expenses: number;
  netReturn: number;
}

const initialData: InputData = {
  schoolsParticipating: 645,
  studentsPerSchool: 60,
  studentPackageCost: 650,
  mobileGamersTarget: 30000,
  mobileGameRechargeAmount: 35,
  averageRechargesPerYear: 10,
  annualPartnershipInvestment: 10000000,
  schoolInternetCost: 900,
  esportsCenterCost: 15000000,
};

const InteractiveFinancialModel: React.FC = () => {
  const [inputs, setInputs] = useState<InputData>(initialData);
  const [projections, setProjections] = useState<Projection[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: parseFloat(value) }));
  };

  useEffect(() => {
    const calculateProjections = (): Projection[] => {
      const educationData = [
        {
          year: 2025,
          studentsRatio:
            480 /
            (initialData.schoolsParticipating * initialData.studentsPerSchool),
        },
        {
          year: 2026,
          studentsRatio:
            1296 /
            (initialData.schoolsParticipating * initialData.studentsPerSchool),
        },
        {
          year: 2027,
          studentsRatio:
            3600 /
            (initialData.schoolsParticipating * initialData.studentsPerSchool),
        },
        {
          year: 2028,
          studentsRatio:
            7680 /
            (initialData.schoolsParticipating * initialData.studentsPerSchool),
        },
        {
          year: 2029,
          studentsRatio:
            15480 /
            (initialData.schoolsParticipating * initialData.studentsPerSchool),
        },
        {
          year: 2030,
          studentsRatio:
            28380 /
            (initialData.schoolsParticipating * initialData.studentsPerSchool),
        },
      ];

      const mobileGamingData = [
        { year: 2025, rechargesRatio: 2 / initialData.averageRechargesPerYear },
        { year: 2026, rechargesRatio: 4 / initialData.averageRechargesPerYear },
        { year: 2027, rechargesRatio: 6 / initialData.averageRechargesPerYear },
        { year: 2028, rechargesRatio: 8 / initialData.averageRechargesPerYear },
        {
          year: 2029,
          rechargesRatio: 10 / initialData.averageRechargesPerYear,
        },
        {
          year: 2030,
          rechargesRatio: 15 / initialData.averageRechargesPerYear,
        },
      ];

      return educationData.map((edu, index) => {
        const mobile = mobileGamingData[index];
        const participatingStudents = Math.round(
          edu.studentsRatio *
            inputs.schoolsParticipating *
            inputs.studentsPerSchool
        );

        const educationIncome =
          participatingStudents * inputs.studentPackageCost * 12;
        const mobileGamingIncome =
          inputs.mobileGamersTarget *
          inputs.mobileGameRechargeAmount *
          mobile.rechargesRatio *
          inputs.averageRechargesPerYear;
        const totalIncome = educationIncome + mobileGamingIncome;

        const annualPartnershipInvestment = inputs.annualPartnershipInvestment;
        const schoolInternetCost =
          inputs.schoolsParticipating * inputs.schoolInternetCost * 12;
        const esportsCenterCost = index === 0 ? inputs.esportsCenterCost : 0;
        const expenses =
          annualPartnershipInvestment + schoolInternetCost + esportsCenterCost;

        return {
          year: edu.year,
          educationIncome,
          mobileGamingIncome,
          totalIncome,
          expenses,
          netReturn: totalIncome - expenses,
        };
      });
    };

    setProjections(calculateProjections());
  }, [inputs]);

  const formatNumber = (value: number): string => {
    return new Intl.NumberFormat("en-US").format(value);
  };

  const customTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow">
          <p className="font-bold">{`Year: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: N$ ${formatNumber(entry.value as number)}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const totalIncome = projections.reduce(
    (sum, year) => sum + year.totalIncome,
    0
  );
  const totalExpenses = projections.reduce(
    (sum, year) => sum + year.expenses,
    0
  );
  const totalNetReturn = totalIncome - totalExpenses;
  const roi = ((totalNetReturn / totalExpenses) * 100).toFixed(2);

  return (
    <div className="financial-model-container">
      <div className="header-container">
        <img
          src={process.env.PUBLIC_URL + "/nesa-logo.webp"}
          alt="NESA Logo"
          className="logo nesa-logo"
        />
        <h1 className="model-title">Interactive Financial Model (2025-2030)</h1>
        <img
          src={process.env.PUBLIC_URL + "/mtc-logo.webp"}
          alt="MTC Logo"
          className="logo mtc-logo"
        />
      </div>

      <div className="input-sections">
        <div className="input-section">
          <h2>Education Project Inputs</h2>
          <InputField
            label="Schools Participating"
            name="schoolsParticipating"
            value={inputs.schoolsParticipating}
            onChange={handleInputChange}
          />
          <InputField
            label="Students per School"
            name="studentsPerSchool"
            value={inputs.studentsPerSchool}
            onChange={handleInputChange}
          />
          <InputField
            label="Student Package Cost (N$ per month)"
            name="studentPackageCost"
            value={inputs.studentPackageCost}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-section">
          <h2>Mobile Gaming Inputs</h2>
          <InputField
            label="Mobile Gamers Target"
            name="mobileGamersTarget"
            value={inputs.mobileGamersTarget}
            onChange={handleInputChange}
          />
          <InputField
            label="Mobile Game Recharge Amount (N$)"
            name="mobileGameRechargeAmount"
            value={inputs.mobileGameRechargeAmount}
            onChange={handleInputChange}
          />
          <InputField
            label="Average Recharges per Year"
            name="averageRechargesPerYear"
            value={inputs.averageRechargesPerYear}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-section">
          <h2>Expense Inputs</h2>
          <InputField
            label="Annual Partnership Investment (N$)"
            name="annualPartnershipInvestment"
            value={inputs.annualPartnershipInvestment}
            onChange={handleInputChange}
          />
          <InputField
            label="School Internet Cost (N$ per month)"
            name="schoolInternetCost"
            value={inputs.schoolInternetCost}
            onChange={handleInputChange}
          />
          <InputField
            label="Esports Center Cost (N$)"
            name="esportsCenterCost"
            value={inputs.esportsCenterCost}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="chart-container">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={projections}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(value)
              }
            />
            <Tooltip content={customTooltip} />
            <Legend />
            <Line
              type="monotone"
              dataKey="totalIncome"
              stroke="#00309c"
              name="Total Income"
            />
            <Line
              type="monotone"
              dataKey="expenses"
              stroke="#e1261c"
              name="Expenses"
            />
            <Line
              type="monotone"
              dataKey="netReturn"
              stroke="#00843d"
              name="Net Return"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="summary-section">
        <SummaryCard title="Total Income" value={totalIncome} />
        <SummaryCard title="Total Expenses" value={totalExpenses} />
        <SummaryCard title="Total Net Return" value={totalNetReturn} />
        <SummaryCard title="ROI" value={`${roi}%`} />
      </div>
    </div>
  );
};

export default InteractiveFinancialModel;
