import React from "react";

interface SummaryCardProps {
  title: string;
  value: number | string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, value }) => (
  <div className="bg-mtc-blue text-white p-4 rounded-lg shadow">
    <h3 className="text-lg font-bold mb-2">{title}</h3>
    <p className="text-2xl font-semibold">
      {typeof value === "number" ? `N$ ${value.toLocaleString()}` : value}
    </p>
  </div>
);

export default SummaryCard;
